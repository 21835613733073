<template>
    <div>
        <full-overlay :show="loading" />
        <b-table :items="list" :fields="fields" striped class="mt-3">
            <template #cell(name)="{ value }">
                <div>{{ value.ru }}</div>
                <div>{{ value.en }}</div>
            </template>
            <template #cell(settings)="{ item: { id } }">
                <b-button-group>
                    <b-button @click="edit(id)">Редактировать</b-button>
                    <b-button @click="remove(id)" variant="danger">
                        Удалить
                    </b-button>
                </b-button-group>
            </template>
        </b-table>
        <b-modal
            title="Редактирование"
            v-model="modal.show"
            hide-footer
            size="lg"
        >
            <b-form @submit.prevent="send">
                <b-form-row>
                    <b-col>
                        <b-form-group label="Имя">
                            <b-form-input v-model="modal.name.ru" required />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="[EN] Имя">
                            <b-form-input v-model="modal.name.en" />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-group label="Точка на карте">
                    <yandex-map
                        :coords="modal.coords"
                        zoom="12"
                        @click="setCoords"
                        ymap-class="map-container"
                        :settings="{
                            apiKey: '9dc27bdb-0abe-474e-bb69-0549aaf9a1fa',
                        }"
                    >
                        <ymap-marker :coords="modal.coords" marker-id="place" />
                    </yandex-map>
                </b-form-group>
                <b-button type="submit" class="mt-1">Сохранить</b-button>
            </b-form>
        </b-modal>
    </div>
</template> 

<style lang="scss">
.map-container {
    height: 500px;
}
</style>

<script>
import FullOverlay from "@c/FullOverlay.vue";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
export default {
    components: { FullOverlay, yandexMap, ymapMarker },
    name: "FestivalPlace",
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            modal: this.getModal(),
            list: [],
            fields: [
                {
                    key: "id",
                    label: "#",
                },
                {
                    key: "name",
                    label: "Имя",
                },
                {
                    key: "settings",
                    label: "",
                },
            ],
        };
    },
    mounted() {
        this.update();
    },
    methods: {
        setCoords(e) {
            this.modal.coords = e.get("coords");
        },
        async update() {
            this.loading = true;
            const { list } = await this.$fetchAdmin(
                `/festival/${this.id}/place`
            ).then((r) => r.json());
            this.list = list;
            this.loading = false;
        },
        getModal: () => ({
            id: 0,
            show: false,
            name: {
                ru: "",
                en: "",
            },
            coords: [53.727096, 91.451355],
        }),
        add() {
            this.modal = this.getModal();
            this.modal.show = true;
        },
        async edit(id) {
            this.loading = true;
            const { one } = await this.$fetchAdmin(
                `/festival/place/${id}`
            ).then((r) => r.json());
            this.modal = Object.assign(this.getModal(), one);
            this.modal.show = true;
            this.loading = false;
        },
        async send() {
            this.loading = true;
            const body = new FormData();
            body.append("id", this.modal.id);
            body.append("name[ru]", this.modal.name.ru);
            body.append("name[en]", this.modal.name.en);
            body.append("coords[0]", this.modal.coords[0]);
            body.append("coords[1]", this.modal.coords[1]);
            await this.$fetchAdmin(`/festival/${this.id}/place`, {
                method: "POST",
                body,
            }).then((r) => r.json());
            this.loading = false;
            this.modal.show = false;
            this.update();
        },
        async remove(id) {
            const st = await this.$bvModal.msgBoxConfirm(
                "Подтвердите удаление"
            );
            if (!st) {
                return false;
            }
            this.loading = true;
            await this.$fetchAdmin(`/festival/place/${id}`, {
                method: "DELETE",
            }).then((r) => r.json());
            this.loading = false;
            this.update();
        },
    },
};
</script>